import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from 'antd';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Icon from 'components/common/icon';

import { useTranslation } from 'react-i18next';

import {
  TEAM,
  MODAL,
  CONTACTS,
  CHATS,
  ASSETS,
  DETAIL_ORDER,
  SHOW_NEWYEAR_CONTENT_FEATURE_FLAG,
  SHOW_VALENTINE_DAY_CONTENT_FEATURE_FLAG
} from 'constants/index';

import { useCollapsedContactPanel } from 'components/contacts-view/views/chats/contact-panel/use-collapsed-contact-panel';
import { useCollapsedContractorPanel as useOrderStatusContractorCollapsed } from 'components/orders-view/drawers/details/contractor-panel/use-collapsed-contractor-panel';

import {
  getIsIndividual,
  getIsWorkspacesLoading,
  getWorkspaceId,
  getActiveEvents
} from 'store/workspace';
import {
  getRouterPathname,
  getRouterUrlQuery,
  getRouterUrlSubmenu,
  getRouterUrlView
} from 'store/router';

import ROUTES from 'config/routes';
import usePrevious from 'hooks/common/use-previous';

import {
  LogoIcon,
  LogoCircleIcon,
  LogoCircleNewYearIcon,
  LogoNewYearIcon,
  LogoCircleValentineDayIcon,
  LogoValentineDayIcon
} from '../../icons';
import SideMenu from '../template/side-menu';
import Sider from '../template/sider';
import useCollapsedSider from '../template/sider/use-collapsed-sider';

import styles from './sider.module.scss';

const cloneRoutes = cloneDeep(ROUTES);

export const SiderMenu = () => {
  const [routes, setRoutes] = useState(cloneRoutes);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];

  const [collapsedSider, setCollapsedSider] = useCollapsedSider();
  const [collapsedContact, setCollapsedContact] = useCollapsedContactPanel();
  const [collapsedOrderStatusContractor, setCollapsedOrderStatusContractor] =
    useOrderStatusContractorCollapsed();

  const pathname = useSelector(getRouterPathname);
  const view = useSelector(getRouterUrlView);
  const subview = useSelector(getRouterUrlSubmenu);
  const activeWorkspaceId = useSelector(getWorkspaceId);
  const events = useSelector(getActiveEvents);

  const isIndividual = useSelector(getIsIndividual, shallowEqual);
  const isLoadingWorkspaces = useSelector(getIsWorkspacesLoading);
  const prevIsIndividual = usePrevious(isIndividual);

  const { t } = useTranslation('Menu');

  const setCollapsed = value => {
    if (!collapsedContact && view === CONTACTS && subview.includes(CHATS)) {
      setCollapsedContact(true);
    }

    if (!collapsedOrderStatusContractor && modal === DETAIL_ORDER) {
      setCollapsedOrderStatusContractor(true);
    }

    setCollapsedSider(value);
  };

  const getSelectedKeys = () => {
    const subpathnames = pathname.split('/').slice(2);
    const selectedKeys = [];

    subpathnames.map((item, index) => {
      if (selectedKeys.length) {
        return selectedKeys.push(`${selectedKeys[index - 1]}/${item}`);
      }
      return selectedKeys.push(`/${item}`);
    });

    return selectedKeys;
  };

  const selectedKeys = getSelectedKeys();

  const getFilteredRoutes = useCallback(() => {
    if (!isIndividual) return cloneRoutes;

    // eslint-disable-next-line no-shadow
    const routes = { ...cloneRoutes };

    if (isIndividual) {
      delete routes[TEAM];
      delete routes[ASSETS];
    }

    return routes;
  }, [isIndividual]);

  const setNewCountRoutes = () => {
    const routesWithCounts = getFilteredRoutes();
    const filteredRoutes = Object.keys(routes).filter(
      key => routesWithCounts[key]
    );

    filteredRoutes.map(route => {
      if (routesWithCounts[route].children) {
        return Object.keys(routesWithCounts[route].children).forEach(child => {
          routesWithCounts[route].children[child].count =
            (events[route] && events[route][child]) || 0;
        });
      }

      return null;
    });

    setRoutes(routesWithCounts);
  };

  useEffect(() => {
    if (!prevIsIndividual && prevIsIndividual !== undefined && isIndividual) {
      // const isTeam = pathname.includes(TEAM);
      // if (isTeam) {
      //   goTo(`/${activeWorkspaceId}${ORDERS}`); // CHANGE AND CLEAN UP
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isIndividual]);

  useEffect(() => {
    if (Object.keys(events || {}).length) {
      setNewCountRoutes();
    } else {
      setRoutes(getFilteredRoutes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspaceId, isIndividual, events]);

  const featureIcons = [
    {
      enabled: useFeatureIsOn(SHOW_NEWYEAR_CONTENT_FEATURE_FLAG),
      icons: { default: LogoNewYearIcon, collapsed: LogoCircleNewYearIcon }
    },
    {
      enabled: useFeatureIsOn(SHOW_VALENTINE_DAY_CONTENT_FEATURE_FLAG),
      icons: {
        default: LogoValentineDayIcon,
        collapsed: LogoCircleValentineDayIcon
      }
    }
  ];

  const activeFeatures = featureIcons.filter(feature => feature.enabled);

  const getLogoComponent = () => {
    if (activeFeatures.length === 1) {
      return collapsedSider
        ? activeFeatures[0].icons.collapsed
        : activeFeatures[0].icons.default;
    }
    return collapsedSider ? LogoCircleIcon : LogoIcon;
  };

  return (
    <Sider className={styles.root}>
      <Icon component={getLogoComponent()} className={styles.logo} />

      <div className={styles.menuWrapper}>
        <SideMenu
          selectable={!isLoadingWorkspaces}
          routes={routes}
          selectedKeys={[...selectedKeys]}
          collapsed={collapsedSider}
        />
      </div>

      <div className={styles.supportWidgetSpace}>
        <Button
          type="text"
          className={classnames(styles.btnCollapse, {
            [styles.collapsed]: collapsedSider
          })}
          onClick={() => setCollapsed(!collapsedSider)}
        >
          <Icon
            type="left"
            style={{ fontSize: 16 }}
            side={collapsedSider ? 'up' : 'default'}
          />
          {!collapsedSider && <span>{t('CollapseMenuBtn')}</span>}
        </Button>
      </div>
    </Sider>
  );
};

export default SiderMenu;
